@media print {
  .no-print {
    display: none !important;
  }
  .print * div,
  td,
  th,
  tr {
    break-inside: avoid;
    page-break-inside: avoid;
    display: block;
  }
}
